<template>
  <!-- 习题 -->
  <div class="main-container">
    <div class="exercises">
      <div v-for="(el, i) in info" :key="i" class="item">
        <div class="item_cont">
          <div class="title">
            <span>【{{ el.type | typeFilter }}】</span>
          </div>
          <div class="name">
            <span class="sort">{{i+1}},</span>
            <span v-html="el.title"></span>
          </div>
          <div v-for="(item, inx) in el.option" :key="inx" class="item_li">
            <div v-if="!el.answer_option_id">
              <div
                class="item_sort"
                :class="item.check ? 'active' : ''"
                @click="checkAnswer(el, item)"
              >{{ inx | sortFilter }}</div>
            </div>
            <div v-else>
              <div
                class="item_sort"
                :class="el.answer_option_id === item.id ? 'active' : ''"
              >{{ inx | sortFilter }}</div>
            </div>
            <div class="item_opt" v-html="item.content"></div>
          </div>
        </div>
        <div v-if="isCheck" class="checkOpt">
          <div
            v-if="el.answer_option_id && el.answer_option_id === id"
            class="el-icon-circle-check iconsCheck"
          ></div>
          <div v-else class="el-icon-circle-close iconsClose"></div>
          <div class="desc">
            <span>正确答案:</span>
            <span v-html="checkOpt" class="opt"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAnswer } from "@/api/outline"

// 题目类型：0：其他，1：单选，2：多选，3：判断，4：填空，5：简答
const typeMap = {
  0: '其他题',
  1: '单选题',
  2: '多选题',
  3: '判断题',
  4: '填空题',
  5: '简答题'
}

const sortMap = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
}
export default {
  name: "tabsProblem",
  props: {
    // id: {
    //   type: Number,
    //   default: 0
    // },
    info: {
      type: Array,
      default: []
    }
  },
  components: {},
  data () {
    return {
      isCheck: false,
      checkOpt: '',
      id: '',
    }
  },
  watch: {
    info: {
      handler (val) {
        this.isCheck = false
        val.forEach(item => {
          if (item.answer_option_id) {
            this.isCheck = true
          }
          item.option.forEach(el => {
            el.check = false
            if (el.is_right) {
              this.id = el.id
              this.checkOpt = el.content
            }
          })
        })
      },
      immediate: true,
      deep: true,
    }
  },

  filters: {
    typeFilter (status) {
      return typeMap[status]
    },
    sortFilter (status) {
      return sortMap[status]
    }
  },
  created () {
  },
  methods: {
    checkAnswer (el, data) {
      this.isCheck = false
      this.info.forEach(item => {
        item.option.forEach(el => {
          el.check = false
        })
      })
      data.check = true
      this.$emit('checkMsg', true)
      this.$forceUpdate()
      let params = {
        outline_file_time_stem_id: el.outline_file_time_stem_id,
        option_id: data.id
      }
      getAnswer(params).then(res => {
        if (res.code === 0) {
          this.isCheck = res.data.is_right ? false : true
          this.checkOpt = res.data.content
        }
      }).catch(error => { })
    }
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .exercises {
    .item_cont {
      margin-top: 14px;

      .title {
        font-weight: bold;
        padding: 0px 0px 14px 0px;
      }

      .name {
        padding-bottom: 18px;
        display: flex;
        .sort {
          margin-right: 6px;
        }
        ::v-deep p {
          margin: 0px;
        }
      }

      .item_li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        .item_sort {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border: 1px solid #dfdfdf;
          border-radius: 50%;
          cursor: pointer;
        }

        .active {
          border: none;
          color: #fff;
          border: 1px solid rgba($color: #409eff, $alpha: 0.8);
          background-color: rgba($color: #409eff, $alpha: 0.8);
        }

        .item_opt {
          padding-left: 12px;
          ::v-deep p {
            margin: 0px;
          }
        }
      }
      .item_li:last-child {
        margin-bottom: 30px;
      }
    }

    .checkOpt {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      padding: 6px 4px;
      background-color: #f1f1f1;

      .iconsClose {
        color: red;
        font-size: 24px;
      }
      .iconsCheck {
        color: #67c23a;
        font-size: 24px;
      }

      .desc {
        margin-left: 10px;
        display: flex;
        .opt {
          margin-left: 6px;
          color: rgba($color: #409eff, $alpha: 0.8);
        }
        ::v-deep p {
          margin: 0px;
        }
      }
    }
  }
}
</style>
  